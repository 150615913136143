@import "./abstracts/variables";
@import "~bootstrap/scss/bootstrap";

@import "./components/nav.scss"; 
@import "./components/header.scss"; 
@import "./components/buttons.scss"; 
@import "./components/marquee.scss";
@import "./components/video-container.scss";
@import "./components/lineup.scss";
@import "./components/stages.scss";

@import "./components/typo.scss";

html,body{
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}